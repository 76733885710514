import React, { useContext, useEffect, useMemo, useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
    Input,
} from "reactstrap";
import Style from "./ModalSelectProduct.module.css";
import { SimpleLineIcon } from "../../../../../components/Icons";
import ProductCard from "./Components/ProductCard/ProductCard";
import Pagination from "./Components/Pagination/Pagination";
import useFetchProducts from "../../Hooks/useFetchProducts";
import { useFormik } from "formik";
import useFetchSizeLists from "../../Hooks/useFetchSizeLists";
import Loading from "react-loading-animation";
import { PaymentContext } from "../../Context/PaymentContext";
import classNames from "classnames";
import { getProductStatus } from "../../Helper";
import useFetchCategoryLists from "../../Hooks/useFetchCategoryLists";

const Spacer = (props) => {
    const { width = 0, height = 0, style, ...otherProps } = props;

    return <div style={{ width, height, ...style }} {...otherProps} />;
};

const HorizontalDivider = (props) => {
    const {
        width = "100%",
        height = 1,
        color = "#cfd8dc",
        space = 10,
        style,
    } = props;

    return (
        <div
            style={{
                width,
                height,
                backgroundColor: color,
                marginTop: space,
                marginBottom: space,
                ...style,
            }}
        />
    );
};

const ModalSelectProduct = (props) => {
    const { onClose, ...modalProps } = props;
    const { product: productSelectedFromStore, setProduct } =
        useContext(PaymentContext);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [clickedSort, setClickedSort] = useState(0);
    const [toggleResetFetch, setToggleResetFetch] = useState(0);

    const form = useFormik({
        initialValues: {
            status: "",
            condition: "",
            size: "",
            category_id: "",
            keyword: "",
            sort: "",
        },
    });
    const { status, condition, size, category_id, keyword, sort } = form.values;

    const { sizes } = useFetchSizeLists();
    const { categories } = useFetchCategoryLists();
    const { isFetching, products, response, setPage, reFetch, setProducts, setResponse } =
        useFetchProducts({
            status,
            condition,
            size,
            category_id,
            keyword,
            sort,
            showExpiredModal: props.showExpiredModal,
        });

    const onClickClearFilter = () => {
        if (isFetching) {
            return;
        }

        form.setValues({
            ...form.values,
            status: "",
            condition: "",
            size: "",
            category_id: "",
            sort: "",
        });

        setToggleResetFetch(toggleResetFetch + 1);
    };

    const addProduct = (data) => {
        setSelectedProducts([...selectedProducts, data]);
    };

    const removeProduct = (data) => {
        const temp = selectedProducts.filter(
            (selectedProduct) => selectedProduct.id !== data.id
        );
        setSelectedProducts(temp);
    };

    const onClickContinue = () => {
        setProduct(selectedProducts);
        onCloseModal();
    };

    const resetFetch = () => {
        if (isFetching) {
            return;
        }

        reFetch(true);
    };

    const onCloseModal = () => {
        form.resetForm();
        setProducts([]);
        setResponse(undefined);
        setPage(1);
        setSelectedProducts(productSelectedFromStore || []);
        onClose();
    };

    useEffect(() => {
        if (toggleResetFetch > 0) {
            resetFetch();
        }
    }, [toggleResetFetch]);

    useEffect(() => {
        if (clickedSort > 0) {
            reFetch();
        }
    }, [sort]);

    useEffect(() => {
        if (selectedProducts.length === 0) {
            setSelectedStatus("");
        }

        if (selectedProducts.length === 1) {
            const product = selectedProducts[0];
            const status = getProductStatus(product);
            setSelectedStatus(status);
        }
    }, [selectedProducts]);
    
    useEffect(() => {
        setSelectedProducts(productSelectedFromStore || []);
    }, [productSelectedFromStore]);

    return (
        <Modal {...modalProps} className={Style["modal-select-product"]}>
            <ModalHeader
                className={Style["modal-header"]}
                cssModule={{ "modal-title": "w-100" }}
            >
                <div className={Style["header-wrapper"]}>
                    <Input
                        type="select"
                        placeholder="All Status"
                        defaultValue="All Status"
                        className={Style["input"]}
                        value={status}
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldValue("status", value);
                        }}
                    >
                        <option value={""}>All Status</option>
                        <option value={"consignment"}>Consignment</option>
                        <option value={"standard"}>Standard</option>
                        <option value={"pre_order"}>Pre-Order</option>
                    </Input>

                    <Spacer width={8} />

                    <Input
                        type="select"
                        placeholder="All Condition"
                        defaultValue="All Condition"
                        className={Style["input"]}
                        value={condition}
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldValue("condition", value);
                        }}
                    >
                        <option value={""}>All Condition</option>
                        <option value={"BARU"}>Brand New</option>
                        <option value={"STANDARD_99"}>Standard 99%</option>
                        <option value={"BEKAS"}>Used</option>
                    </Input>

                    <Spacer width={8} />

                    <Input
                        type="select"
                        placeholder="All Size"
                        defaultValue="All Size"
                        className={Style["input"]}
                        value={size}
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldValue("size", value);
                        }}
                    >
                        <option value={""}>All Size</option>
                        {sizes.map((size, index) => {
                            const key = index + 1;

                            return (
                                <option key={`size-${key}`} value={size.US}>
                                    {size.US}
                                </option>
                            );
                        })}
                    </Input>

                    <Spacer width={8} />

                    <Input
                        type="select"
                        placeholder="All Category"
                        defaultValue="All Category"
                        className={Style["input"]}
                        value={category_id}
                        onChange={(e) => {
                            const value = e.target.value;
                            form.setFieldValue("category_id", value);
                        }}
                    >
                        <option value={""}>All Category</option>
                        {categories.map((category, index) => {
                            const key = index + 1;
                            let category_name = category.name
                            if (category_name === 'handbags') {
                                category_name = 'luxury'
                            } else if (category_name === 'lifestyles') {
                                category_name = 'Electronics & Collectibles'
                            }

                            return (
                                <option key={`category-${key}`} value={category.id}>
                                    {category_name.charAt(0).toUpperCase() + category_name.slice(1)}
                                </option>
                            );
                        })}
                    </Input>

                    <Spacer width={8} />

                    <Button color="primary" onClick={resetFetch}>
                        Apply Filter
                    </Button>

                    <Spacer width={16} />

                    {(status || condition || size) && (
                        <div
                            className={classNames(
                                Style["clear-filter"],
                                isFetching && Style["disabled"]
                            )}
                            onClick={onClickClearFilter}
                        >
                            Clear Filter
                        </div>
                    )}

                    <div className={Style["mobile-only"]}>
                        <Spacer width={16} />
                        <div
                            className={Style["close-button"]}
                            onClick={onCloseModal}
                        >
                            <SimpleLineIcon iconType="close" />
                        </div>
                        <Spacer width={16} />
                    </div>

                    <div className={Style["right-wrapper"]}>
                        <div style={{ fontSize: 14 }}>Sort By:</div>
                        <Spacer width={8} />

                        <Input
                            type="select"
                            placeholder="Sort By"
                            defaultValue=""
                            className={Style["input"]}
                            value={sort}
                            onChange={(e) => {
                                const value = e.target.value;
                                setClickedSort(clickedSort + 1);
                                form.setFieldValue("sort", value);
                            }}
                        >
                            <option value="">Default</option>
                            <option value="createdAt_desc">Newest</option>
                            <option value="updatedAt_desc">Last Updated</option>
                            <option value="updatedAt_asc">Oldest</option>
                            <option value="askingPrice_desc">
                                Highest Price
                            </option>
                            <option value="askingPrice_asc">
                                Lowest Price
                            </option>
                        </Input>

                        <Spacer width={32} className={Style["desktop-only"]} />

                        <div
                            className={Style["close-button"]}
                            onClick={onCloseModal}
                        >
                            <SimpleLineIcon iconType="close" />
                        </div>
                    </div>
                </div>
                <Spacer height={12} />
                <div className={Style["header-wrapper"]}>
                    <div style={{ flex: 9 }}>
                        <Input
                            placeholder="Search by Product Name, CG ID or Seller Email"
                            className={Style["input full-width"]}
                            value={keyword}
                            onChange={(e) => {
                                const value = e.target.value;
                                form.setFieldValue("keyword", value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    resetFetch();
                                }
                            }}
                        />
                    </div>

                    <Spacer width={8} />
                    <div style={{ flex: 1 }}>
                        <Button
                            className={Style["full-width"]}
                            color="primary"
                            onClick={resetFetch}
                        >
                            Search
                        </Button>
                    </div>
                </div>
                <HorizontalDivider style={{ marginBottom: 0 }} />
                <div className={Style["sub-header-wrapper"]}>
                    {isFetching && (
                        <span style={{ fontSize: 12 }}>Fetching Data...</span>
                    )}

                    {!isFetching && !!response?.total && (
                        <span style={{ fontSize: 12 }}>
                            Showing {response?.from} to {response?.to} of{" "}
                            {response?.total} items.
                        </span>
                    )}

                    <span>
                        <Button color="link" onClick={resetFetch}>
                            <SimpleLineIcon iconType="refresh" />
                        </Button>
                    </span>
                </div>
                <HorizontalDivider space={0} />
            </ModalHeader>
            <ModalBody>
                <div className={Style["body-wrapper"]}>
                    {isFetching && <Loading />}

                    {!isFetching && (
                        <div className={Style["product-list-wrapper"]}>
                            {products.map((product, index) => {
                                const key = index + 1;
                                const isSelected = selectedProducts.some(
                                    (selectedProduct) =>
                                        selectedProduct.id === product.id
                                );
                                const onClickButton = isSelected
                                    ? removeProduct
                                    : addProduct;

                                return (
                                    <ProductCard
                                        key={`product-card-${key}`}
                                        data={product}
                                        onClickButton={onClickButton}
                                        isSelected={isSelected}
                                        selectedStatus={selectedStatus}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className={Style["footer-wrapper"]}>
                    <div>
                        <Pagination
                            isFetching={isFetching}
                            response={response}
                            setPage={setPage}
                        />
                    </div>
                    <div>
                        <Button
                            disabled={!selectedProducts.length}
                            color="primary"
                            onClick={onClickContinue}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

ModalSelectProduct.defaultProps = {
    onClose: () => { },
    isOpen: true,
};

export default ModalSelectProduct;
